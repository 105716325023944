<template>
  <div>
    <el-card>
      <el-form slot="header" inline size="small"  @submit.native.prevent="search(params)">
        <el-form-item label="企业">
          <el-input placeholder="企业" v-model="params.name"></el-input>
        </el-form-item>
        <el-form-item><el-button type="primary" native-type="submit">查询</el-button></el-form-item>
      </el-form>
      <el-table :data="list" style="width: 100%" v-loading="loading" stripe empty-text="没有数据">
        <el-table-column prop="companyName" label="企业"></el-table-column>
        <el-table-column label="日期">
          <template slot-scope="scope">
                <date-time :time="scope.row.date" :show-time="false" style="font-size: 12px; color: #444;"/>
              </template>
        </el-table-column>
        <el-table-column prop="ttm" label="市盈率（TTM）"></el-table-column>
        <el-table-column prop="lyr" label="市盈率（LYR）"></el-table-column>
      </el-table>
      <el-pagination
        @current-change="changeCurrent"
        @size-change="changeSize"
        :current-page.sync="page.current"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="page.size"
        layout="sizes, prev, pager, next, total"
        :total="page.total">
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      posting: false
    }
  },
  computed: {
    ...mapState('companies', ['list', 'error', 'loading', 'page', 'query', 'params']),
    ...mapState('account', { account: 'user' })
  },
  methods: {
    ...mapActions('companies', ['search', 'subQuery', 'refresh', 'changeCurrent', 'changeSize'])

  },
  mounted () {
    this.search()
  }
}
</script>
